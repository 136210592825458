import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Advanced photolithography equipment`}</h4>
    <p>{`The Netherlands, Japan, and a small number of other countries are the dominant producers of lithography equipment, critical for the production of chips and photomasks. In particular, the Netherlands and Japan are exclusive providers of advanced photolithography scanners, necessary for mass-production of advanced chips.`}</p>
    <p>{`Photolithography scanners use ultraviolet light to draw intricate, nanoscale patterns into semiconductor wafers, creating the billions of tiny circuits contained in a single advanced logic chip. An extreme ultraviolet (EUV) scanner refracts a beam of 13.5 nm ultraviolet light through a photomask, transferring that pattern to a photoresist chemical applied as a layer on the chip. The light dissolves parts of the photoresist in the circuit pattern. The newly created photoresist pattern is etched into a permanent chip substrate below the photoresist. Throughout this process, the scanner precisely moves the wafer and the photomask helps build the design.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      